
@media (max-width: 400.98px) {
  
   #cashVocher{
    display:flex;
    flex-direction: column !important;

    
   }
    }
.card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
}

@media print{
    .printContent{
        display: block !important;
        margin: 10px 20px 40px 10px;
    }
}