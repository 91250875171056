
.card{
    border-radius: 10px;
}
.form-control {
    border-radius: 25px;

}

.btn {
    border-radius: 25px;

}
.form-check a{
 color: #2d6a4f;
 font-weight: 500;
}
h5{
 color: #2d6a4f;
 font-weight: 500;
}
.carousel-caption p{
 color: #2d6a4f;
 font-weight: 500;
}
p a{
    color: #2d6a4f;
    font-weight: 500;
}

@media (max-width: 991.98px) {
    #carousel{
        display: none;
    }
    h1{
        margin-top: 20px;
    }
    }