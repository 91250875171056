
@media (max-width: 767.98px) {
  
   #cashVocher{
    display:flex;
    flex-direction: column !important;   
   }
}
#btnStyle{
 button{
     border-radius: 7px;
 }
}
.card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
}
