#hero-section{
    border-radius: 0 0 15px 0;
    margin-right:13px ;
    background-color: #f3f3f3;
    padding: 0;
}
.card{
    p{
        line-height: 20px;
    }
}
#balanceCard{
    background-color: #63d471;
    background-image: linear-gradient(315deg, #63d471 0%, #233329 74%);
    

}
#profileCard{
    width: 85%;
    padding: 0 10px;
}