@media print {
    // .afterPrint{
    //     margin: 10px;
    //     page-break-after: always;
    // }
    .abcd{
        display: inline-block !important;
    }

}
table{
    width: 100%;
}
@media print {
    tr:nth-child(13n) {
      page-break-after: always;
    }
  }