ul {
    li {
        font-size: 17px;
        border-radius: 4px;
        line-height: 25px;
    }

    span {
        color: #005a16;
    }

    a {
        i {
            color: #005a16;
        }
    }

    .active {
        background-color: #b6e3c6;


    }

    li:hover {
        background-color: #b7e4c7;

    }

    .dropdown-item:hover {
        background-color: #b7e4c7;

    }
}